export const menuItems = [
    {
        id: 1,
        label: 'module.profile',
        icon: 'mdi mdi-account-circle',
        link: '/profile'
    },
    {
        id: 2,
        label: 'module.user',
        icon: 'mdi mdi-account',
        link: '/user'
    },
    {
        id: 3,
        label: 'module.meetingRoom',
        icon: 'fas fa-calendar',
        link: '/meeting-room'
    },
    {
        id: 5,
        label: 'module.allBooking',
        icon: 'fas fa-calendar',
        link: '/all-booking'
    },
    {
        id: 4,
        label: 'module.logout',
        icon: 'mdi mdi-power',
        link: '/logout'
    },
    
]